<template>
  <div class="chating">
    <div class="header">
      <!-- <div class="title">
                23小时或20次对话后问题关闭
            </div> -->
      <div class="doc_info">
        <img :src="doctorInfo.image" alt="">
        <div class="info">
          <div class="info_right">
            <p>
              <span>{{doctorInfo.name}}</span>
              <span>{{doctorInfo.title}}</span>
            </p>
            <p>{{doctorInfo.reply_num}}人咨询</p>
          </div>
          <div class="hospital">
            <p>{{doctorInfo.clinic_name}}</p>
            <p>{{doctorInfo.hospital}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <p class="title">医生回复仅为建议，具体诊疗请前往医院进行</p>
      <div class="user">
        <div class="describe" v-if="problemDetail.questionContent">{{problemDetail.questionContent}}</div>
        <!-- <img :src="itemFile" class="img" v-for="itemFile in item.file" :key="itemFile" v-if="item.type === 'image'" alt=""> -->
        <img src="../assets/img/touxiang.png" class="user_icon" alt="">
      </div>
      <div class="doctor" v-for="item in problemDetail.callInfo.phoneSummary" :key="item.id">
        <img :src="doctorInfo.image" alt="" class="user_icon">
        <div class="describe" v-if="item.type === 'text'">{{item.text}}</div>
        <img :src="item.file" class="img" v-if="item.type === 'image'" alt="">
      </div>
    </div>
    <!-- <div class="footer">
      <img src="../assets/img/photo_icon.png" alt="">
      <textarea class="input" placeholder="请输入内容"></textarea>
      <button>发送</button>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      problemDetail: {},
      problemId: '',
      doctorInfo: {}
    }
  },
  created() {
    this.docProblemDetail(this.$route.query.problem_id);
    this.problemId = this.$route.query.problemId;
    // this.doctorDetail(this.$route.query.doctorId);
  },
  methods: {
    // 问题详情接口
    docProblemDetail(problemId) {
      let params = {
        orderId: problemId
      }
      this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
        // console.log('res====>', res);
        if (res.data.code == 200) {
          if (res.data.data.callInfo.phoneSummary) {
            // for (let i = 0; i < res.callInfo.phoneSummary.length; i++) {
            res.data.data.callInfo.phoneSummary = JSON.parse(res.data.data.callInfo.phoneSummary);
            // }
          }
          this.problemDetail = res.data.data;
          // this.problemDetail.questionContent = JSON.parse(this.problemDetail.questionContent);
          // for (let i = 0;)
          let item = this.problemDetail.questionContent;
          // for (let k = 0; k < item.length; k++) {
          //   if (item[k].type === 'image') {
          //     item[k].file = item[k].file.split(',');
          //   }
          // }
          this.doctorDetail(res.data.data.doctorId);
        }
      })
    },
    // 医生详情
    doctorDetail(doctorId) {
      let params = {
        doctor_id: doctorId
      }
      this.$http('post', '/cooperation/server/doctor/detail', params).then(res => {
        // console.log('医生', res);
        if (res.data.error == 0) {
          this.doctorInfo = res.data;
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.chating {
  min-height: 100%;
  background: #f8f8f8;
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    .title {
      width: 100%;
      padding: 0.14rem 0;
      background: #fff;
      color: #39b4af;
      text-align: center;
      font-size: 0.24rem;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
    }
    .doc_info {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0.2rem;
      width: calc(100% - 0.4rem);
      img {
        width: 1.14rem;
        height: 1.14rem;
        margin-right: 0.2rem;
      }
      .info {
        width: 100%;
        .info_right {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;
          align-items: center;
          p:nth-child(1) {
            span:nth-child(1) {
              font-size: 0.32rem;
              color: #333;
              margin-right: 0.2rem;
            }
            span:nth-child(2) {
              font-size: 0.28rem;
              color: #777777;
            }
          }
          p:nth-child(2) {
            font-size: 0.28rem;
            color: #afafaf;
          }
        }
        .hospital {
          display: flex;
          align-items: center;
          p:nth-child(1) {
            width: 1.08rem;
            height: 0.38rem;
            line-height: 0.42rem;
            border: 1px solid #42cec8;
            border-radius: 0.42rem;
            color: #42cec8;
            font-size: 0.24rem;
            text-align: center;
            margin-right: 0.12rem;
          }
          p:nth-child(2) {
            color: #555;
            font-size: 0.26rem;
          }
        }
      }
    }
  }
  .content {
    margin-top: 2rem;
    // margin-bottom: 1rem;
    padding: 0.22rem;
    // height: calc(100% - 2.44rem);
    width: calc(100% - 0.44rem);
    // text-align: center;
    .title {
      padding: 0.08rem 0rem;
      width: 5.68rem;
      background: #cfcccf;
      color: #fff;
      margin: 0 auto;
      margin-top: 0.12rem;
      border-radius: 4px;
      text-align: center;
      font-size: 0.26rem;
    }
    .user {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 0.6rem;
      .describe {
        // width: 4.2rem;
        // height: 1.08rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 0.6rem 0.46rem 0 0.6rem;
        background: #42cec8;
        padding: 0.16rem 0.43rem;
        padding-right: 0.3rem;
        font-size: 0.28rem;
        color: #fff;
      }
      .user_icon {
        height: 1.08rem;
        width: 1.08rem;
        border-radius: 50%;
        margin-left: 0.14rem;
      }
      .img {
        width: 2rem;
        height: 2rem;
      }
    }
    .doctor {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-top: 0.6rem;
      .describe {
        width: 4.2rem;
        // height: 1.08rem;
        display: flex;
        align-items: center;
        border-radius: 0.46rem 0.8rem 0.8rem 0rem;
        background: #fff;
        border: 1px solid #42cec8;
        padding: 0.16rem 0.43rem;
        // padding-right: 0.3rem;
        font-size: 0.28rem;
        color: #42cec8;
      }
      .user_icon {
        height: 1.08rem;
        width: 1.08rem;
        border-radius: 50%;
        margin-right: 0.14rem;
      }
      .img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.3rem 0.2rem;
    width: calc(100% - 0.4rem);
    img {
      width: 0.56rem;
      height: 0.46rem;
      margin-right: 0.32rem;
    }
    .input {
      //   width: 5rem;
      border-radius: 0.8rem;
      height: 0.36rem;
      flex: 1;
      margin-right: 0.34rem;
      background: #f5f7f8;
      padding: 0.16rem 0.3rem;
      border: 0;
    }
    .input::-webkit-input-placeholder {
      color: #b5b5b5;
      font-size: 0.3rem;
    }
    button {
      background: #42cec8;
      border: 0;
      font-size: 0.28rem;
      color: #fff;
      padding: 0.12rem 0.3rem;
      border-radius: 4px;
    }
  }
}
</style>


